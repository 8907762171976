<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img background-cata">
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 mx-auto self-center">
            <div>
                <div slot="no-body" class="full-page-bg-color login-box">
                    <div class="vx-row no-gutter justify-center items-center mb-10">
                        <img :src="logoCata" >
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-full mx-auto self-center">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">{{ $t('login.redefinePassword') }}</h4>
                                    <p>{{ $t('login.enterNewPassword') }}</p>
                                </div>
                                <!--<vs-input type="email" label-placeholder="Email" v-model="value1" class="w-full mb-6" />-->
                                <vs-input type="password" :placeholder="$t('login.password')" v-model="password" class="w-full mb-6" />
                                <vs-input type="password" :placeholder="$t('login.passwordConfirm')" v-model="passwordConfirm" class="w-full mb-8" />

                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                                    <vs-button type="border" to="/pages/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">{{ $t('login.backLogin') }}</vs-button>
                                    <vs-button class="w-full sm:w-auto" @click="resetPassword">Reset</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../http/axios/index.js"
import i18n from '../../i18n/i18n'

export default {
    data() {
        return {
            password: '',
            passwordConfirm: '',
            logoCata: require('@/assets/images/logo/logo_toda_branca.png')
        }
    },
    methods: {
        resetPassword() {
            // Loading
            this.$vs.loading()

            axios.post("/auth/password/reset", {password: this.password, recoveryToken: this.$route.params.id})
                .then((data) => { 
                    this.$vs.loading.close()
                    
                    if (!data.data.success){
                        this.$vs.notify({
                            title: i18n.t('dialog.error'),
                            text: data.data.error,
                            color: 'danger'
                        })
                    }
                    else{
                        this.$router.push('/pages/login').catch(() => {})
                        this.$vs.notify({
                            title: i18n.t('dialog.success'),
                            text: i18n.t('login.resetOkMessage'),
                            color: 'success'
                        })
                    }
                })
                .catch(error => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: i18n.t('dialog.error'),
                        text: error.message,
                        color: 'danger'
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";

.background-cata {
  background: $cata-bg;
}

.login-box {
  background:none !important;
}

.login-box p, h4 {
  color:white;
}


</style>
